<template>
    <div style="width: 100%;">
        <a-result style="width: 100vw;" v-if="isCorrect" status="success" title="正在开始下载" >
            <template slot="subTitle">
                <div>
                    {{FileName}}
                </div>
                <div style="margin-top: 10px;">
                    {{  `若未开始，刷新或点击重新下载`  }}
                </div>
            </template>
            <div style="display: flex; justify-content: center;">
                <a-button type="primary" @click="handleDownLoad">重新下载</a-button>
            </div>
        </a-result>
        <a-result v-else status="error" title="无法下载" sub-title="请检查路径是否正确">
        </a-result>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, computed } from 'vue-demi';
import { Base64 } from 'js-base64';
import { Modal } from 'ant-design-vue';
import router from '../../router';
document.title = '下载'
import { getExperimentFilePath } from '@/api/experiment.js'
export default {
    props: {},
    setup(props, context) {
        const isCorrect = ref(false);
        const FileName = computed(() => {
            return context.root.$route.query.fileName ? Base64.decode(decodeURIComponent(context.root.$route.query.fileName)) : '';
        })
        onBeforeMount(() => {
            /* document.title = '下载'
            const url = context.root.$route.query.url ? Base64.decode(decodeURIComponent(context.root.$route.query.url)) : '';
            const fileName = context.root.$route.query.fileName ? Base64.decode(decodeURIComponent(context.root.$route.query.fileName)) : '';
            if (url && fileName) {
                isCorrect.value = true;
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = url;
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
                //saveAs(url, fileName);
            } */
            /* isCorrect.value = true; */
            // console.log(context.root.$route.fullPath)
            if (context.root.$route.query.fileName && context.root.$route.query.code) {
                isCorrect.value = true;
                handleDownLoad();
            }

        })

        onMounted(() => {
            document.title = '下载'
        })

        const handleReload = () => {
            /* document.title = '下载'
            const url = context.root.$route.query.url ? Base64.decode(decodeURIComponent(context.root.$route.query.url)) : '';
            const fileName = context.root.$route.query.fileName ? Base64.decode(decodeURIComponent(context.root.$route.query.fileName)) : '';
            if (url && fileName) {
                isCorrect.value = true;
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = url;
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
                //saveAs(url, fileName);
            } */
            router.replace({
                name: 'login',
                query: {
                    // redirect: context.$route
                    redirect: context.root.$route.fullPath
                }
            })
        }

        const handleDownLoad = async () => {
            const redirect = context.root.$route.fullPath;
            const token = localStorage.getItem('Authorization');
            if (!token) {
                Modal.warning({
                    title: '提示',
                    content: '请先登录后，再完成下载。',
                    onOk: async () => {
                        router.replace({
                            name: 'login',
                            query: {
                                // redirect: context.$route
                                redirect: redirect
                            }
                        })
                    },
                    okText: '去登录'
                })
                return
            }
            const code = context.root.$route.query.code ? Base64.decode(decodeURIComponent(context.root.$route.query.code)) : '';
            const fileName = context.root.$route.query.fileName ? Base64.decode(decodeURIComponent(context.root.$route.query.fileName)) : '';
            /*   const res = await  axios({
                  method: 'post',
                  url: '/api/experiment/getFileUrl',
                  params: {
                      isPreview: false,
                      filePath: code,
                      isFromCloudDisk: false,
                  }
              }) */

            const res = await getExperimentFilePath({
                isPreview: false,
                filePath: code,
                isFromCloudDisk: false,
                saveAsName: fileName
            })
            if (res.success) {
                if (fileName) {
                    isCorrect.value = true;
                    const elink = document.createElement('a');
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = res.data;
                    //elink.target = '_blank';
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
                //window.open(res.data.data, "_");
            }
            else if (res.code === 401) {
                Modal.warning({
                    title: '提示',
                    content: `${res.message} ，再完成下载。`,
                    onOk: async () => {
                        router.replace({
                            name: 'login',
                            query: {
                                // redirect: context.$route
                                redirect: redirect
                            }
                        })
                    },
                    okText: '去登录'
                })
            }
            else {
                Modal.error(res.message);
            }
        }

        return {
            isCorrect,
            handleReload,
            handleDownLoad,
            FileName
        }
    }
}
</script>